<!--
 * @Author       : JiangChao
 * @Date         : 2024-10-30 10:07:33
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-12-10 14:01:41
 * @Description  : 
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="85%"
    :append-to-body="true"
  >
    <template slot="title">
      <div class="font-heavy" style="font-size: 18px;color: #303133">
        <!-- {{ $t("add_device_to_the_alert_rule") }} -->
        {{ $t("add_loggers_to_shipment") }}
      </div>
    </template>
    <el-form
      :model="form"
      status-icon
      ref="resetRef"
      label-width="130px"
      class="demo-ruleForm"
    >
      <div class="top">
        <div class="left">
          <el-input
            :placeholder="$t('please_enter_keyword')"
            clearable
            style="flex: 1; width: 300px"
            v-model="form.keyword"
            autocomplete="off"
            class="input-select-custom-width"
          >
          </el-input>
          <el-button
            style="
              width: 145px;
              margin-left: 10px;
              background: #1a72f4;
              border: 1px solid #1a72f4;
            "
            type="primary"
            @click="getDeviceList"
            >{{ $t("query") }}</el-button
          >
        </div>
        <div class="right">
          <el-button
            style="width: 145px; border: 1px solid #1a72f4; color: #1a72f4"
            plain
            @click="dialogFormVisible = false"
            >{{ $t("cancel") }}</el-button
          >
          <el-button
            style="width: 145px; background: #1a72f4; border: 1px solid #1a72f4"
            type="primary"
            @click="handelOk"
          >
            {{ $t("confirm") }}</el-button
          >
        </div>
      </div>
    </el-form>
    <!-- 表格 -->
    <el-table
      :data="sensorList"
      style="width: 100%"
      ref="loggerTable"
      @selection-change="handleLoggerSelectionChange"
      border
      :header-cell-style="{
        background: '#f6f6fa',
        textAlign: 'center',
        color: 'black',
      }"
      :cell-style="{ 'text-align': 'center' }"
      :default-sort="{ prop: 'deviceId', order: 'descending' }"
      @sort-change="handleSortChange"
      :empty-text="$t('no_data')"
    >
      <el-table-column type="selection" width="55" :selectable="rowSelectable">
      </el-table-column>
      <el-table-column :label="$t('device_id')" prop="deviceId" sortable>
      </el-table-column>
      <el-table-column :label="$t('device_name')" prop="name">
        <template v-slot:default="scope">
          <span> {{ scope.row.name || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('device_status')" prop="status">
        <template v-slot:default="scope">
          <div class="status-box" v-if="scope.row.status == '3'">
            <div class="point" style="background: #ed6e36"></div>
            <span>{{ $t("ended") }}</span>
          </div>
          <div class="status-box" v-if="scope.row.status == '1'">
            <div class="point" style="background: #02c06f"></div>
            <span>{{ $t("recording") }}</span>
          </div>
          <div class="status-box" v-if="scope.row.status == '2'">
            <div class="point" style="background: #0a8af6"></div>
            <span>{{ $t("delay_recording") }}</span>
          </div>
          <div class="status-box" v-if="scope.row.status == '0'">
            <div class="point" style="background: #0a8af6"></div>
            <span>{{ $t("not_started") }}</span>
          </div>
          <div class="status-box" v-if="scope.row.status == '9'">
            <div class="point" style="background: #0a8af6"></div>
            <span>{{ $t("discard") }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('affiliated_organization')"
        prop="orgName"
        sortable
      >
        <template v-slot:default="scope">
          <span> {{ scope.row.orgName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('owner')" prop="owner" sortable>
        <template v-slot:default="scope">
          <span> {{ scope.row.owner || "-" }}</span>
        </template>
      </el-table-column>
      <!-- 告警状态 1：告警 0：未告警 (设备有过告警，就一直记录为告警) -->
      <!-- <el-table-column
        :label="$t('alert_status')"
        prop="alarmInd"
        width="150px"
      >
        <template v-slot:default="scope">
          <div v-if="scope.row.alarmInd == 1" class="alert-style">
            <img src="../../../assets/ic_alert.png" width="15" /><span>{{
              scope.row.alarmNum
            }}</span>
          </div>
          <div v-else-if="scope.row.alarmInd == 0" class="alert-style">
            <img src="../../../assets/ic_no_alert.png" width="15" /><span>{{
              $t("no_alert")
            }}</span>
          </div>
        </template>
      </el-table-column> -->
      <!-- temp温度 wetness湿度 power电量 refreshDate最后更新时间 -->
      <!-- prop="refreshDate" -->
      <!-- :sortable="true" -->
      <!-- <el-table-column :label="$t('latest_measurements')" min-width="280px">
        <template v-slot:default="scope">
          <div class="measurements-col">
            {{ Number(scope.row.temp) | convertTemperature(setSelectedTempType)
            }}{{ setSelectedTempType }}
            <span style="margin-left: 5px">{{ scope.row.wetness }}</span>
            <template v-if="scope.row.battery">
              <img
                v-if="
                  Math.round(scope.row.battery.value) > 90 &&
                  Math.round(scope.row.battery.value) <= 100
                "
                src="../../../assets/ic_battery_full.png"
                width="20"
              />
              <img
                v-if="
                  Math.round(scope.row.battery.value) > 50 &&
                  Math.round(scope.row.battery.value) <= 90
                "
                src="../../../assets/ic_battery_half.png"
                width="20"
              />
              <img
                v-if="
                  Math.round(scope.row.battery.value) > 20 &&
                  Math.round(scope.row.battery.value) <= 50
                "
                src="../../../assets/ic_battery_low.png"
                width="20"
              />
              <img
                v-if="
                  Math.round(scope.row.battery.value) >= 0 &&
                  Math.round(scope.row.battery.value) <= 20
                "
                src="../../../assets/ic_battery_empty.png"
                width="20"
              />
              <span style="margin-left: 0px"
                >{{ scope.row.battery.value || "-" }}%</span
              >

              <span style="margin-left: 5px">
                {{
                  scope.row.refreshDate
                    | formatDateWithTimeZone(
                      setSelectedTimeZone,
                      setSelectedLanguage
                    )
                }}
              </span>
            </template>
            <template v-else>
            </template>
          </div>
        </template>
      </el-table-column> -->

      <!-- <el-table-column :label="$t('address')" prop="address" width="150px"> -->
      <!-- </el-table-column> -->
      <!-- 纬度latitude 经度longitude -->
      <!-- {{ scope.row.longitude }} {{ scope.row.latitude }} -->

      <!-- <el-table-column :label="$t('latest_location')" prop="" width="150px">
        <template v-slot:default="scope">
          <div class="location-col">
            {{ scope.row.address }}
          </div>
        </template>
      </el-table-column> -->
      <el-table-column
        :label="$t('start_time')"
        prop="startTime"
        width="150"
        sortable
      >
        <template v-slot:default="scope">
          {{
            scope.row.startTime
              | formatDateWithTimeZone(setSelectedTimeZone, setSelectedLanguage)
          }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('end_time')"
        prop="endTime"
        width="150"
        sortable
      >
        <template v-slot:default="scope">
          {{
            scope.row.endTime
              | formatDateWithTimeZone(setSelectedTimeZone, setSelectedLanguage)
          }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 10px; text-align: center"
      background
      @current-change="handleCurrentChange"
      :current-page="Number(this.pageNum)"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="Number(this.pageSize)"
      layout="total,pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </el-dialog>
</template>

<script>
import {
  getShipmentCanAddDeviceListApi,
  //  applyAlarmSettingApi
} from "@/api/api.js";

export default {
  name: "AddDeviceToNewAlertDialog",
  data() {
    return {
      dialogFormVisible: false,
      sensorList: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      form: {},
      selectedRows: [], // 存储已勾选行的数据
      isChina: true,
      sceneId: "",
    };
  },
  methods: {
    showDialog(id) {
      this.sceneId = id;
      this.dialogFormVisible = true;
      this.getDeviceList();
    },
    handelOk() {
      if (this.selectedRows.length <= 0) {
        this.$message.error(this.$t("at_least_select_one_device"));
        return;
      }
      let temp = [];
      this.selectedRows.forEach((element) => {
        temp.push(element.deviceId);
      });
      // let params = {
      //   sceneId: this.sceneId,
      //   deviceIds: temp,
      // };

      // applyAlarmSettingApi(params).then((r) => {
      //   console.log("r: ", r);

      //   this.$message.success(this.$t("operation_successful"));
      this.dialogFormVisible = false;
      this.$emit("ok", temp);
      // });
    },

    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      // this.queryParams.page.pageIndex = val;
      this.pageNum = 1;
      this.pageNum = `${val}`;

      this.getDeviceList();
    },
    getDeviceList() {
      let params = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        ...this.form,
      };

      getShipmentCanAddDeviceListApi(params).then((response) => {
        this.sensorList = response.data.records;
        this.total = response.data.total;

        // 保存当前的选择状态
        const currentSelection = this.$refs.loggerTable.selection;
        // 确保在 DOM 更新后执行
        this.$nextTick(() => {
          // 清除现有的选择状态，因为数据已经改变
          // this.$refs.loggerTable.clearSelection();

          // 重新设置勾选状态
          currentSelection.forEach((row) => {
            const foundRow = this.sensorList.find(
              (sensorRow) => sensorRow.deviceId === row.deviceId
            );
            if (foundRow) {
              this.$refs.loggerTable.toggleRowSelection(foundRow, true);
              // console.log("Row selected:", foundRow);
            }
          });
        });
      });
    },

    handleLoggerSelectionChange(val) {
      // console.log("val: ", val);
      this.selectedRows = val;
    },
    rowSelectable(row) {
      // console.log("row: ", row);
      return row.enabled === 1;
    },
    handleSortChange({ column, prop, order }) {
      console.log("handleSortChange--order: ", order);
      console.log("handleSortChange--column: ", column);
      console.log("handleSortChange--prop: ", prop);
      // if (prop === "battery.timestamp") {
      //   this.sortData(order);
      // }
    },
    sortData(order) {
      let reverse = order === "descending" ? -1 : 1;
      this.sensorList.sort((a, b) => {
        // 对于 Unix 时间戳，我们直接使用数字比较
        return reverse * (a.timestamp - b.timestamp);
      });
    },
  },
  props: {},
  components: {},
  computed: {
    setSelectedTimeZone() {
      return this.$store.getters.selectedTimeZone;
    },
    setSelectedTempType() {
      return this.$store.getters.selectedTempType;
    },
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },

  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
    this.isChina = JSON.parse(localStorage.getItem("isChina"));
  },
  created() {},
};
</script>

<style scoped lang="less">
.measurements-col {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.alert-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  img {
    margin: 0 5px;
  }
}
.demo-ruleForm {
  margin-top: 20px;
}
.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 20px 0;
  .left {
    display: flex;
    flex-direction: row;
  }
}

.status-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .point {
    width: 6px;
    height: 6px;

    border-radius: 3px;
    margin-right: 4px;
  }
}
</style>
