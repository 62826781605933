<!--
 * @Author       : JiangChao
 * @Date         : 2024-11-13 16:26:49
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-11-13 17:18:50
 * @Description  : 运单模板列表弹窗
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="55%"
    :append-to-body="true"
  >
    <template slot="title">
      <div class="font-heavy" style="font-size: 18px;color: #303133">
        {{ $t("change_template") }}
      </div>
    </template>
    <div class="form">
      <div class="right">
        <el-button
          style="width: 145px; border: 1px solid #1a72f4; color: #1a72f4"
          plain
          @click="dialogFormVisible = false"
          >{{ $t("cancel") }}</el-button
        >
        <el-button
          style="width: 145px; background: #1a72f4; border: 1px solid #1a72f4"
          type="primary"
          @click="handelOk"
        >
          {{ $t("confirm") }}</el-button
        >
      </div>
    </div>
    <!-- 表格 -->
    <el-table
      :data="templateList"
      style="width: 100%"
      ref="templateTable"
      border
      :header-cell-style="{
        background: '#f6f6fa',
        textAlign: 'center',
        color: 'black',
      }"
      :cell-style="{ 'text-align': 'center' }"
      @selection-change="handleTemplateSelectionChange"
      :empty-text="$t('no_data')"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <!-- <el-table-column :label="$t('shipment_id')" prop="shipmentId"> </el-table-column> -->
      <el-table-column :label="$t('template_name')" prop="name">
      </el-table-column>
      <el-table-column :label="$t('alert_rules_key')" prop="config">
        <template slot-scope="scope">
          <div>
            {{
              Number(scope.row.config.min)
                | convertTemperature(setSelectedTempType)
            }}{{ setSelectedTempType }} {{ setSelectedTempType }}

            ~
            {{
              Number(scope.row.config.max)
                | convertTemperature(setSelectedTempType)
            }}{{ setSelectedTempType }}
          </div>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { getShipmentTemplateListApi } from "@/api/api.js";

export default {
  name: "ShipmentTemplateListDialog",
  data() {
    return {
      dialogFormVisible: false,
      form: {},
      templateSelection: [],
      isChina: true,
      templateList: [],
      savedTemplates: [],
      systemTemplates: [],
    };
  },
  methods: {
    showDialog() {
      this.dialogFormVisible = true;
      // this.$refs.templateTable.clearSelection();
      this.getShipmentTemplateList();
    },

    getShipmentTemplateList() {
      getShipmentTemplateListApi({
      }).then((r) => {
        console.log("=====", r);
        this.templateList = r.data;

        // 初始化保存的模板和系统模板数组
        // this.savedTemplates = [];
        // this.systemTemplates = [];

        // // 遍历数据并根据 templateInd 分类
        // this.templateList.forEach((item) => {
        //   if (item.templateInd === "1") {
        //     this.savedTemplates.push(item);
        //   } else if (item.templateInd === "9") {
        //     this.systemTemplates.push(item);
        //   }
        // });

        console.log("this.templateList: ", JSON.stringify(this.templateList));

      });
    },
    handelOk() {
      if (this.templateSelection.length > 1) {
        this.$message.error(this.$t("each_time_add_one_template"));
        return;
      }
      this.dialogFormVisible = false;
      this.$emit("changeAlertRules", this.templateSelection[0]);
    },
    handleTemplateSelectionChange(val) {
      console.log("val: ", JSON.stringify(val[0]));
      this.templateSelection = val;
    },
  },
  props: {},
  components: {},
  computed: {
    setSelectedTimeZone() {
      return this.$store.getters.selectedTimeZone;
    },
    setSelectedTempType() {
      return this.$store.getters.selectedTempType;
    },
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },

  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
    this.isChina = JSON.parse(localStorage.getItem("isChina"));
    this.userId == localStorage.getItem("userId");
    console.log("this.userId: ", this.userId);
  },
  created() {},
};
</script>

<style scoped lang="less">
.form {
  display: flex;
  justify-content: right;
  padding-bottom: 20px;
  .top {
    position: absolute;
    right: 0;
  }
  /deep/.el-checkbox .el-checkbox__inner {
    display: none;
  }
}
</style>
